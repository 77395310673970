/**
 * Reddit service provider
 * Share doc: https://www.reddit.com/dev/api#POST_api_submit + https://stackoverflow.com/a/32533431
 * Counter doc: https://www.reddit.com/dev/api/#GET_search
 * Scores are in `child.data.score` and are number of up-votes minus number of down-votes for an URL submitted to Reddit
 * Displayed counter is calculated as sum of counts for the 5 most upvoted posts for this url.
 */
export default {
    counterUrl: 'https://www.reddit.com/search.json?q=url:{url}&sort=top&type=link&limit=5',
    convertNumber: (response) => {
        const parsedResponse = JSON.parse(response);
        let totalUpvotes = 0;
        parsedResponse.data.children.forEach((child) => {
            if (child.data && child.data.score) {
                totalUpvotes += child.data.score;
            }
        });
        return totalUpvotes;
    },
    popupUrl: 'https://reddit.com/submit?url={url}&title={title}',
    popupWidth: 785,
    popupHeight: 550,
    knownParams: ['url', 'title', 'counter'],
    svgIconPath: '15.936 8.186 C 15.936 7.227 15.159 6.45 14.2 6.45 C 13.732 6.45 13.308 6.636 12.995 6.937 C 11.808 6.08 10.173 5.527 8.352 5.464 L 9.143 1.742 L 11.727 2.291 C 11.758 2.949 12.296 3.473 12.961 3.473 C 13.646 3.473 14.202 2.918 14.202 2.233 C 14.202 1.548 13.646 0.992 12.961 0.992 C 12.474 0.992 12.057 1.276 11.854 1.685 L 8.968 1.071 C 8.888 1.054 8.804 1.069 8.735 1.114 C 8.666 1.159 8.617 1.23 8.6 1.31 L 7.717 5.462 C 5.869 5.514 4.207 6.068 3.005 6.934 C 2.693 6.634 2.271 6.45 1.804 6.45 C 0.845 6.45 0.068 7.227 0.068 8.186 C 0.068 8.892 0.489 9.498 1.094 9.769 C 1.067 9.942 1.052 10.117 1.052 10.295 C 1.052 12.966 4.162 15.132 7.998 15.132 C 11.834 15.132 14.944 12.966 14.944 10.295 C 14.944 10.118 14.929 9.944 14.903 9.773 C 15.511 9.503 15.936 8.894 15.936 8.186 Z M 4.031 9.427 C 4.031 8.743 4.588 8.186 5.272 8.186 C 5.955 8.186 6.512 8.743 6.512 9.427 C 6.512 10.11 5.955 10.667 5.272 10.667 C 4.588 10.667 4.031 10.11 4.031 9.427 Z M 10.947 12.704 C 10.101 13.549 8.478 13.615 8.001 13.615 C 7.524 13.615 5.902 13.549 5.057 12.704 C 4.931 12.578 4.931 12.375 5.057 12.249 C 5.182 12.124 5.386 12.124 5.511 12.249 C 6.045 12.783 7.186 12.972 8.001 12.972 C 8.817 12.972 9.958 12.783 10.493 12.249 C 10.619 12.124 10.822 12.124 10.947 12.249 C 11.073 12.375 11.073 12.578 10.947 12.704 Z M 10.729 10.667 C 10.045 10.667 9.488 10.11 9.488 9.427 C 9.488 8.743 10.045 8.186 10.729 8.186 C 11.413 8.186 11.969 8.743 11.969 9.427 C 11.969 10.11 11.413 10.667 10.729 10.667',
};
